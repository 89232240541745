import React, { FC } from 'react'
import { Container, DotsLoader } from 'ui'

interface FullPageLoaderProps {
  text?: string
}

const FullPageLoader: FC<FullPageLoaderProps> = ({ text }) => {
  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      textAlign="center"
      height="100%"
    >
      <DotsLoader text={text} />
    </Container>
  )
}

export default FullPageLoader
