import axios from 'axios'
import { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import nextI18nextConfig from 'next-i18next.config'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Container } from 'ui'
import FullPageLoader from '~/components/full-page-loader'
import { NextPageWithLayout } from './@types/page'

const Login: NextPageWithLayout = () => {
  const { t } = useTranslation('common')

  const router = useRouter()

  useEffect(() => {
    const login = async () => {
      const endpoint = router.query.redirectUrl
        ? `/api/auth/identity-auth?redirectUrl=${router.query.redirectUrl}`
        : '/api/auth/identity-auth'
      const { data } = await axios.get(endpoint)
      window.location.href = data.authorizeEndpoint
    }
    login()
  }, [])
  return (
    <Container height="100vh">
      <FullPageLoader />
    </Container>
  )
}

export default Login

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const translations = await serverSideTranslations(
    ctx.locale ?? 'en',
    ['common'],
    nextI18nextConfig
  )
  return {
    props: {
      translations,
    },
  }
}
